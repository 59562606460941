import React from 'react'
import { Link } from 'gatsby'
import Helmet from "react-helmet";



var title = "JavaScript Full-Stack Bootcamp Student's Form"

const JSFSBstudentsForm = () => (
	<div className="white">
	<Helmet
	title='JavaScript Full-Stack Bootcamp Student&#39;s Form'
	meta={[
		{name: 'charset', content: 'UTF-8'}, 
		{name: "viewport", content: "initial-scale = 1.0"},
		{name: 'og:locale', content: 'en_US' },
		{name: 'og:type', content: 'article'},
		{ name: 'description', content: 'JavaScript Full-Stack Student\'s Form.' },
		{ name: 'keywords', content: 'JavaScript Full-Stack Student\'s Form.' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}/>
		<div 
		className='GForm'>

			<iframe 
			style={{
				marginLeft: 'auto',
				marginRight: 'auto', 
				display: 'block'}}
				src="https://docs.google.com/forms/d/e/1FAIpQLScCgLI3HCjnU8fT0UjYq1EpCncWGjtTDktXZr-QAALbEj6cJA/viewform?embedded=true" 
				width="100%" 
				height="820" 
				frameBorder="0" 
				marginheight="0" 
				marginwidth="0">Loading...</iframe>
				</div>
				</div>
				)

export default JSFSBstudentsForm
